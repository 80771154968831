<template>
	<section class="col" align-x="center">
		<table>
			<tr>
				<!-- <th></th> -->
				<th class="theme_text">信箱</th>
				<th></th>
			</tr>
			<tr v-for="(item, i) in _list" :key="i">
				<!-- <td>
					<el-checkbox v-model="select_list" :label="item.email" hide-label />
				</td> -->
				<td>{{item.email}}</td>
				<td>{{item.roles | roleLabel}}</td>
			</tr>
		</table>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
	data() {
		return {
			query: new Object,
			select_list: new Array
		}
	},
	computed: {
		...mapState("subscribe", ["_total", "_list"])
	},
	filters: {
		roleLabel(val) {
			if (val && val.includes("donor")) return "椅友會員";
			else if (val && val.includes("member")) return "金粉會員";
			else return "";
		}
	},
	methods: {
		...mapActions("subscribe", ["_getSubscriberList"]),
	},
	async created() {
		const {
			page = 1,
			size = 10
		} = this.$route.query;
		this.$set(this.query, "page", Number(page));
		this.$set(this.query, "size", Number(size));
		await this._getSubscriberList(this.query);
	}
}
</script>

<style lang="scss" scoped>
table {
	background: #fff;
	border-radius: 10px;
	text-align:center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	padding: 1em 2em;
}
</style>